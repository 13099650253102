import React, {useEffect, useState} from 'react';
import { LoadingStyles } from "./Loading.styles";
import { useAuth } from "common/auth";
import LoadingGif from "assets/loading/loading-logo.gif";
import Background from "components/Layout/Background";
import { AppStyles } from "App.styles";

const LoadingElement = ({ type }) => {
  const { newAvatar } = useAuth();

  return newAvatar && !type ? (
    <LoadingStyles.Overlay>
      <Background />
      <LoadingStyles.Suspense>
      <LoadingStyles.LoadingImage src={LoadingGif} alt="" />
        <LoadingStyles.SuspenseTitle>
          Setting up your account
        </LoadingStyles.SuspenseTitle>
      </LoadingStyles.Suspense>
      <AppStyles.Footer>&copy; 2022 Xureal. All rights reserved.</AppStyles.Footer>
    </LoadingStyles.Overlay>
  ) : (
    <LoadingStyles.TransparentOverlay>
      <LoadingStyles.Box>
        <LoadingStyles.LoadingImage src={LoadingGif} alt="" />
        <LoadingStyles.LoadingTitle>
        {type && type === 'saving' ? 'Updating your avatar...' : window.innerWidth <= 350 ? <span style={{fontSize: '14px', fontWeight: 'normal'}}>Loading your avatar...</span> : 'Loading avatar builder…' }
        </LoadingStyles.LoadingTitle>
        <LoadingStyles.LoadingSubtitle>
          This will take a few moments.
        </LoadingStyles.LoadingSubtitle>
      </LoadingStyles.Box>
    </LoadingStyles.TransparentOverlay>
  );
};

export default LoadingElement;
