import React from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";

import "aframe";
import "aframe-extras";
import "./components/Aframe/components/aframe-model-components";
import "./components/Aframe/components/aframe-drag-rotate";

import App from "./App";
import Auth from "common/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./mobile.css";

ReactDOM.render(
  <React.StrictMode>
      <Auth>
        <App />
      </Auth>
  </React.StrictMode>,
  document.getElementById("root")
);
