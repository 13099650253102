import { useCallback, useEffect, useRef, useState } from "react";
import Sketch from "react-p5";

const ColorCanvas = ({ mainCanvasData }) => {
  const [p5Container, setP5Container] = useState({});
  const firstRender = useRef(true)
  const renderToCanvas = useCallback(
    async (p5) => {
      p5.background("black");
      p5.clear();
      p5.noStroke();
      p5.colorMode("hsb");
      p5.scale(0.25);
      if (firstRender.current) {
        firstRender.current = false
        return
      }
      p5.loadImage(mainCanvasData, (img) => {
        p5.blendMode(p5.BLEND);
        p5.image(img, 0, 0, 2048, 2048);
      });
    },
    [mainCanvasData]
  );

  // Initial render of Canvases
  useEffect(() => {
    if (mainCanvasData && !firstRender.current) {
      p5Container.p5.clear();
      p5Container.p5.redraw();
    }
  }, [p5Container.p5, mainCanvasData]);

  const preload = (p5) => {
    setP5Container({ p5 });
  };

  const setup = async (p5, canvasParentRef) => {
    p5.createCanvas(512, 512).parent(canvasParentRef);
    p5.clear();
    p5.noLoop();
  };

  const draw = (p5) => {
    renderToCanvas(p5);
  };

  return (
    <div id="avatar-sub-canvas">
      <Sketch preload={preload} setup={setup} draw={draw} />
    </div>
  );
};

export default ColorCanvas;
