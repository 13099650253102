import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalStyles = {};
ModalStyles.Modal = styled(Modal)`
  position: absolute;
  top: 25%;
  z-index: 98;
  width: 100%;
  .modal-dialog {
    max-width: 551px;
  }
  .modal-content {
    border-radius: 12px;
  }
`;

ModalStyles.Root = styled.div`
  border: 6px solid #ececf3;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.29);
`;

ModalStyles.Close = styled.img`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 24px;
  width: 32px;
`;

ModalStyles.Top = styled.div`
  background-color: #ececf3;
  padding: 60px 70px;
  @media (max-width: 600px) {
    padding: 44px 20px 32px;
  }
`;

ModalStyles.Title = styled.div`
  color: #0d0d0f;
  font-family: "Open Sans";
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
  }
`;

ModalStyles.Body = styled.div`
  margin-top: 22px;
  color: #8b8b97;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0.53px;
  line-height: 21px;
  text-align: center;
`;

ModalStyles.ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    margin: 20px auto;
  }
`;

ModalStyles.ConfirmButton = styled.div`
  cursor: pointer;
  background-color: black;
  color: #ffffff;
  font-family: "Open Sans";
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 19px;
  text-align: center;
  width: 206px;
  margin: 29px auto 36px 12px;
  padding: 16px 28px;
  border: 2px solid #0d0d0f;
  @media (max-width: 600px) {
    margin: 0 auto;
    width: auto;
    padding: 16px 48px;
  }
`;

ModalStyles.CancelButton = styled.div`
  cursor: pointer;
  width: 206px;
  padding: 16px 28px;
  margin: 29px 12px 36px auto;
  color: #0d0d0f;
  border-radius: 5px;
  border: 2px solid #0d0d0f;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 19px;
  text-align: center;
  @media (max-width: 600px) {
    margin: 12px auto 0;
    width: auto;
    padding: 16px 48px;
    border: none;
  }
`;
