import CloseIcon from 'assets/icons/close.png';
import { ModalStyles } from './Modal.styles'
const AvatarModal = ({
  showModal,
  handleClose,
  title,
  body,
  cancelText,
  cancelCallback,
  confirmText,
  confirmCallback,
}) => {
  return (
    <ModalStyles.Modal id="skip-modal" show={showModal} onHide={handleClose}>
      <ModalStyles.Root>
        <ModalStyles.Close src={CloseIcon} onClick={cancelCallback}/>
        <ModalStyles.Top>
          <ModalStyles.Title>{title}</ModalStyles.Title>
          <ModalStyles.Body>{body}</ModalStyles.Body>
        </ModalStyles.Top>
        <ModalStyles.ButtonContainer>
          <ModalStyles.CancelButton
            variant="secondary"
            onClick={cancelCallback}
          >
            {cancelText}
          </ModalStyles.CancelButton>
          <ModalStyles.ConfirmButton
            variant="primary"
            onClick={confirmCallback}
          >
            {confirmText}
          </ModalStyles.ConfirmButton>
        </ModalStyles.ButtonContainer>
      </ModalStyles.Root>
    </ModalStyles.Modal>
  );
};

export default AvatarModal;
