import React, { useCallback, useEffect, useState } from 'react';
import { Entity, Scene } from 'aframe-react';
import { useAuth } from 'common/auth';
import ShadowModel from 'assets/models/shadow.gltf';
import useWindowSize from 'utils/hooks/useWindowSize';


const AframeLayer = ({
  currentTextureMap,
  extendedDrawer,
  catalogItems,
  currentTab,
  camInitialized,
}) => {
  const { userAvatar } = useAuth();
  const { width, height } = useWindowSize();
  const [camera, setCamera] = useState({ x: 0, y: 2.8, z: 2 });
  // const [zoom, setZoom] = useState(0);
  const zoom = 0;
  const screenshotHeight = 400;

  const handleCameraSwitch = useCallback(() => {
    if (currentTab !== 'shirt') {
      if (extendedDrawer < -10) setCamera({ x: 0, y: 2.6, z: 2.5 - zoom });
      if (extendedDrawer >= -10) setCamera({ x: 0, y: 2.85, z: 1.8 - zoom });
      if (extendedDrawer >= 12) setCamera({ x: 0, y: 3, z: 1.4 - zoom });
    }
    if (currentTab === 'shirt') {
      if (extendedDrawer < -10) setCamera({ x: 0, y: 2, z: 4 - zoom }); // low drawer
      if (extendedDrawer >= -10) setCamera({ x: 0, y: 2.3, z: 2 - zoom }); // med drawer
      if (extendedDrawer >= 12) setCamera({ x: 0, y: 2.5, z: 1.6 - zoom }); // hi drawer
    }
    if (width > height) setCamera({ x: 0, y: 2.8, z: 2 });
  }, [setCamera, extendedDrawer, currentTab, zoom, width, height]);

  useEffect(() => {
    if (extendedDrawer !== 0) handleCameraSwitch();
    // start off camera, move to { x: 0, y: 2.45, z: 1.75 } on init
  }, [extendedDrawer, handleCameraSwitch]);

  const CONTENT_URL = process.env.REACT_APP_CONTENT_API_URL;

  return (
    <div
      id='avatar-creator-container'
      style={{ backgroundColor: 'white', width: '10vw', height: '10vh' }}
    >
      <Scene
        id='avatar-creator-scene'
        device-orientation-permission-ui="enabled: false"
        vr-mode-ui='false'
        screenshot={{
          width: Math.floor((width / height) * screenshotHeight),
          height: screenshotHeight,
        }}
      >
        {currentTextureMap && (
          <Entity
            id='avatar-entity-wrapper'
            float={{
              frequency: 1.5,
              amplitude: 1.5,
              camInitialized,
            }}
            drag-rotate
          >
            {catalogItems.model.items.length > 0
              ? catalogItems.model.items.map((hairModel) => {
                  return (
                    <Entity key={'avatar-hair-key-' + hairModel.texture_id}>
                      {hairModel.texture_id !== 'xr-model-3' &&
                        hairModel.texture_id !== 'xr-model-0' && (
                          <Entity
                            id={'avatar-hair-' + hairModel.texture_id}
                            gltf-model={`${CONTENT_URL}/model/${hairModel.texture_id}/sm_hair.gltf`}
                            position={{ x: 0, y: 1.7, z: 0 }}
                            
                            gltf-morph={{
                              morphs: JSON.stringify(userAvatar),
                            }}
                            visible={
                              userAvatar.model.texture_id ===
                              hairModel.texture_id
                            }
                            render-texture={{
                              texture: currentTextureMap,
                              beard: false,
                            }}
                          ></Entity>
                        )}
                    </Entity>
                  );
                })
              : null}
            <Entity
              id={'avatar-model-base'}
              gltf-model={`${CONTENT_URL}/model/xr-model-0/sm_avatar_base.gltf`}
              // gltf-model={`${CONTENT_URL}/model/xr-model-0/sm_avatar_full.gltf`}
              position={{ x: 0, y: 1.7, z: 0 }}
              gltf-morph={{
                morphs: JSON.stringify(userAvatar),
              }}
              render-texture={{
                texture: currentTextureMap,
                beard: userAvatar.beard,
              }}
            ></Entity>
          </Entity>
        )}
        <Entity
          id={`avatar-shadow`}
          gltf-model={ShadowModel}
          float-scale
          position={{ x: 0, y: 1.55, z: 0 }}
          rotation={{ x: 10, y: 0, z: 0 }}
          material='transparent: true; opacity: 0'
        />
        <Entity
          id='camera'
          primitive='a-camera'
          rotation={{ x: -10, y: 0, z: 0 }}
          camera-movement={{
            x: camera.x,
            y: camera.y,
            z: camera.z,
            camInitialized,
          }}
          look-controls={'enabled: false'}
          wasd-controls={'enabled: false'}
          spectator='enabled: true'
          fov={60}
        ></Entity>
      </Scene>
    </div>
  );
};

export default AframeLayer;
