import { useCallback, useEffect, useRef, useState } from "react";
import Sketch from "react-p5";
import { useAuth } from "common/auth";
import { isEmpty } from "utils";
import TeethPartialTexture from "assets/textures/partials/teeth.png";

const MainCanvas = ({
  setMainCanvas,
  currentTextureMap,
  currentColorMap,
  canvasType,
  catalogItems,
}) => {
  const {
    setLoading,
    renderColors,
    setRenderColors,
    renderTextures,
    setRenderTextures,
    renderAllMaterials,
    setRenderAllMaterials,
    userAvatar,
    loading,
    setUserAvatar,
    // newAvatar,
  } = useAuth();
  const [p5Container, setP5Container] = useState({});
  const loadedImageCache = useRef({});
  const prevTextureMap = useRef("");
  const prevColorMap = useRef("");
  const firstRender = useRef(true);
  const firstCanvasRender = useRef(true);
  const rendering = useRef(false);
  const loadingTextures = useRef(false);
  const loadingColors = useRef(false);
  const prevModelData = useRef("");
  const prevColorData = useRef("");
  const canvasBlobURL = useRef("");
  const prevtextureData = useRef("");
  const CONTENT_URL = process.env.REACT_APP_CONTENT_API_URL;

  const handleUpdateModel = useCallback(() => {
    if (
      userAvatar.hair.reference === prevModelData.current ||
      catalogItems.hair.items.length <
        parseInt(
          userAvatar.hair.reference.split("-")[
            userAvatar.hair.reference.split("-").length - 1
          ]
        )
    )
      return;
    prevModelData.current = userAvatar.hair.reference;
    let newModel = catalogItems.model.items.find((model) => {
      return model.texture_id === userAvatar.hair.reference;
    });
    setUserAvatar(
      (prev) => ({
        ...prev,
        model: newModel,
      }),
      "model",
      "none"
    );
  }, [
    userAvatar,
    catalogItems.model.items,
    setUserAvatar,
    catalogItems.hair.items.length,
  ]);

  const imagePromise = (p5, imageSrc, id) => {
    return new Promise((resolve, reject) => {
      p5.loadImage(
        imageSrc,
        (img) => {
          if (id)
            loadedImageCache.current = {
              ...loadedImageCache.current,
              [id]: img,
            };
          resolve(img);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  };

  const renderToCanvas = useCallback(
    async (p5) => {
      if (firstCanvasRender.current) {
        firstCanvasRender.current = false;
        return;
      }
      if (!userAvatar || isEmpty(userAvatar)) return;
      if (
        !renderColors &&
        !renderTextures &&
        !renderAllMaterials &&
        !firstRender.current
      )
        return;
      //-------------------------------
      // This function paints the directions given from the
      // userAvatar to the canvas to create the model texture
      p5.background("black");
      p5.clear();
      p5.noStroke();
      p5.colorMode("hsb");
      if (canvasType === "sub") p5.scale(0.25);
      else p5.scale(0.5);

      // Handle color layering
      const colorCanvasDataURL = document.getElementById("defaultCanvas1")
        ? document.getElementById("defaultCanvas1").toDataURL()
        : currentColorMap;
      if (
        prevColorMap.current === colorCanvasDataURL &&
        loadedImageCache.current.colorMap
      ) {
        p5.blendMode(p5.BLEND);
        p5.image(loadedImageCache.current.colorMap, 0, 0, 2048, 2048);
        // Fetch Color Canvas
      } else {
        if (document.getElementById("defaultCanvas1")) {
          const colorCanvasImage = await imagePromise(
            p5,
            colorCanvasDataURL,
            "colorMap"
          );
          p5.blendMode(p5.BLEND);
          p5.image(colorCanvasImage, 0, 0, 2048, 2048);
        }
        prevColorMap.current = colorCanvasDataURL;
      }
      loadingColors.current = false;

      // Handle texture layering
      const textureCanvasDataURL = document.getElementById("defaultCanvas2")
        ? document.getElementById("defaultCanvas2").toDataURL()
        : currentTextureMap;

      if (
        prevTextureMap.current === textureCanvasDataURL &&
        loadedImageCache.current.textureMap
      ) {
        p5.blendMode(p5.MULTIPLY);
        p5.image(loadedImageCache.current.textureMap, 0, 0, 2048, 2048);

        if (userAvatar.skin.texture_id) {
          if (loadedImageCache.current.skin) {
            p5.blendMode(p5.SOFT_LIGHT);
            p5.image(loadedImageCache.current.skin, 0, 0, 2048, 2048);
          } else {
            const skinTexture = await imagePromise(
              p5,
              `${CONTENT_URL}/${userAvatar.skin.type}/${userAvatar.skin.texture_id}/texture.png`,
              userAvatar.skin.texture_id
            );

            p5.blendMode(p5.SOFT_LIGHT);
            p5.image(skinTexture, 0, 0, 2048, 2048);
          }
        }
        if (userAvatar.shirt.overlay) {
          const loadedImage = await imagePromise(
            p5,
            `${CONTENT_URL}/${userAvatar.shirt.type}/${userAvatar.shirt.texture_id}/${userAvatar.shirt.overlay}`,
            userAvatar.shirt.texture_id
          );
          userAvatar.shirt.texture_id === "xr-shirt-2"
            ? p5.blendMode(p5.OVERLAY)
            : p5.blendMode(p5.BLEND);
          p5.image(loadedImage, 0, 0, 2048, 2048);
        }
        if (loadedImageCache.current.teeth) {
          p5.blendMode("source-over");
          p5.image(loadedImageCache.current.teeth, 0, 0);
        } else {
          const loadedImage = await imagePromise(
            p5,
            TeethPartialTexture,
            "teeth"
          );
          p5.blendMode("source-over");
          p5.image(loadedImage, 0, 0);
        }
        p5.blendMode("source-over");
        p5.fill("#ffffff");
        p5.ellipse(1172, 889, 24);
        p5.ellipse(1408, 864, 24);
        loadingTextures.current = false;
      } else {
        const textureCanvasImage = await imagePromise(
          p5,
          textureCanvasDataURL,
          "textureMap"
        );
        p5.blendMode(p5.MULTIPLY);
        p5.image(textureCanvasImage, 0, 0, 2048, 2048);
        prevTextureMap.current = textureCanvasDataURL;

        if (userAvatar.skin.texture_id) {
          if (loadedImageCache.current.skin) {
            p5.blendMode(p5.SOFT_LIGHT);
            p5.image(loadedImageCache.current.skin, 0, 0, 2048, 2048);
          } else {
            const skinTexture = await imagePromise(
              p5,
              `${CONTENT_URL}/${userAvatar.skin.type}/${userAvatar.skin.texture_id}/texture.png`,
              userAvatar.skin.texture_id,
              "skin"
            );

            p5.blendMode(p5.SOFT_LIGHT);
            p5.image(skinTexture, 0, 0, 2048, 2048);
          }
          loadingColors.current = false;
        }
        if (userAvatar.shirt.overlay) {
          // if (loadedImageCache.current.shirt) {
          //   p5.blendMode(p5.SOFT_LIGHT);
          //   p5.image(loadedImageCache.current.shirt, 1704, 210, 180, 75);
          // } else {
          const loadedImage = await imagePromise(
            p5,
            `${CONTENT_URL}/${userAvatar.shirt.type}/${userAvatar.shirt.texture_id}/${userAvatar.shirt.overlay}`,
            userAvatar.shirt.texture_id,
            "shirt"
          );
          userAvatar.shirt.texture_id === "xr-shirt-2"
            ? p5.blendMode(p5.OVERLAY)
            : p5.blendMode(p5.BLEND);
          p5.image(loadedImage, 0, 0, 2048, 2048);
          // }
        }
        if (loadedImageCache.current.teeth) {
          p5.blendMode("source-over");
          p5.image(loadedImageCache.current.teeth, 0, 0, 2048, 2048);
        } else {
          const loadedImage = await imagePromise(
            p5,
            TeethPartialTexture,
            "teeth"
          );
          p5.blendMode("source-over");
          p5.image(loadedImage, 0, 0, 2048, 2048);
        }
        p5.blendMode("source-over");
        p5.fill("#ffffff");
        p5.ellipse(1172, 889, 24);
        p5.ellipse(1408, 864, 24);
        loadingTextures.current = false;
      }

      // Reset logic
      if (renderColors) setRenderColors(false);
      if (renderTextures) setRenderTextures(false);
      if (renderAllMaterials) setRenderAllMaterials(false);

        p5.canvas.toBlob(canvasBlob => {
          canvasBlobURL.current = URL.createObjectURL(canvasBlob)
          setMainCanvas((prev) => ({ ...prev, main: canvasBlobURL.current }));
      })
      if (firstRender.current) firstRender.current = false;

      setTimeout(() => {
        setLoading("");
      }, 100);

      handleUpdateModel();
      rendering.current = false;
    },
    [
      setMainCanvas,
      setLoading,
      currentTextureMap,
      currentColorMap,
      CONTENT_URL,
      canvasType,
      renderColors,
      setRenderColors,
      renderTextures,
      setRenderTextures,
      loadedImageCache,
      setRenderAllMaterials,
      renderAllMaterials,
      handleUpdateModel,
      userAvatar,
    ]
  );

  const rerenderCanvas = useCallback(() => {
    p5Container.p5?.clear();
    p5Container.p5?.redraw();
  }, [p5Container]);

  const canRenderColors = useCallback(() => {
    return !renderColors || firstRender.current;
  }, [renderColors]);

  // render new color canvas
  useEffect(() => {
    if (canRenderColors()) return;

    if (prevColorData.current !== currentColorMap && !loadingColors.current) {
      loadingColors.current = true;
      rerenderCanvas();
    }
  }, [loading, currentColorMap, rerenderCanvas, canRenderColors]);

  const canRenderTextures = useCallback(() => {
    return !renderTextures || firstRender.current;
  }, [renderTextures]);

  // render new texture canvas
  useEffect(() => {
    if (canRenderTextures()) return;
    const textureCanvasDataURL = document
      .getElementById("defaultCanvas2")
      ?.toDataURL();

    if (
      prevtextureData.current !== currentTextureMap &&
      textureCanvasDataURL === currentTextureMap &&
      !loadingTextures.current
    ) {
      loadingTextures.current = true;
      rerenderCanvas();
    }
  }, [currentTextureMap, rerenderCanvas, canRenderTextures]);

  const canRenderAllMats = useCallback(() => {
    if (!renderAllMaterials || firstRender.current) return true;
    return false;
  }, [renderAllMaterials]);

  const areCanvasesUpdated = useCallback(() => {
    return (
      prevColorMap.current !== currentColorMap &&
      prevTextureMap.current !== currentTextureMap
    );
  }, [currentColorMap, currentTextureMap]);

  // Render AllMat
  // this does not render until both colors and
  // textures have been updated
  useEffect(() => {
    if (canRenderAllMats()) {
      return;
    } else if (!rendering.current && areCanvasesUpdated()) {
      rendering.current = true;
      rerenderCanvas();
    }
  }, [
    renderAllMaterials,
    rerenderCanvas,
    canRenderAllMats,
    areCanvasesUpdated,
  ]);

  // Init Render
  useEffect(() => {
    if (
      firstRender.current &&
      currentColorMap &&
      currentTextureMap &&
      !rendering.current
    ) {
      rendering.current = true;
      rerenderCanvas();
    }
  }, [rerenderCanvas, currentColorMap, currentTextureMap]);

  const preload = (p5) => {
    setP5Container({ p5 });
  };

  const setup = async (p5, canvasParentRef) => {
    p5.createCanvas(1024, 1024).parent(canvasParentRef);
    p5.clear();
    p5.noLoop();
  };

  const draw = (p5) => {
    renderToCanvas(p5);
  };

  return (
    <div>
      <Sketch
        id="avatar-color-canvas"
        preload={preload}
        setup={setup}
        draw={draw}
      />
    </div>
  );
};

export default MainCanvas;
