import axios from "axios";
const AVATAR_API_URL = process.env.REACT_APP_AVATAR_API_URL;
const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API_URL;

export const getUserAvatarData = (userID) => axios.get(`${AVATAR_API_URL}/${userID}/?dataURL=false&thumbnailURL=false`);
export const postUserAvatarData = (userID, dataURL, thumbnailURL, data) => axios.post(`${AVATAR_API_URL}`, {userID, dataURL, thumbnailURL, data});

export const getAllCatalogData = async () => {
    return Promise.all([
        await axios.get(`${CATALOG_API_URL}/skin`),
        await axios.get(`${CATALOG_API_URL}/hair`),
        await axios.get(`${CATALOG_API_URL}/eye`),
        await axios.get(`${CATALOG_API_URL}/shirt`),
        await axios.get(`${CATALOG_API_URL}/beard`),
        await axios.get(`${CATALOG_API_URL}/model`),
    ])
}
