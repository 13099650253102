const AFRAME = window.AFRAME;
const THREE = window.THREE;

AFRAME.registerComponent("drag-rotate", {
  init: function () {
    this.ifMouseDown = false;
    this.ifTouch = false;
    this.x_cord = 0;
    this.topClamp = 2.2;
    this.bottomClamp = 1.3;
    document.addEventListener("mousedown", this.OnDocumentMouseDown.bind(this));
    document.addEventListener("mouseup", this.OnDocumentMouseUp.bind(this));
    document.addEventListener("mousemove", this.OnDocumentMouseMove.bind(this));

    document.addEventListener(
      "touchstart",
      this.OnDocumentTouchStart.bind(this)
    );
    document.addEventListener("touchend", this.OnDocumentTouchEnd.bind(this));
    document.addEventListener(
      "touchcancel",
      this.OnDocumentTouchEnd.bind(this)
    );
    document.addEventListener("touchmove", this.OnDocumentTouchMove.bind(this));
  },
  OnDocumentMouseDown: function (event) {
    if (event.target.localName === "canvas") {
      this.ifMouseDown = true;
      this.x_cord = event.clientX;
    }
  },
  OnDocumentMouseUp: function (event) {
    if (this.ifMouseDown === true) {
      this.ifMouseDown = false;
    }
  },
  OnDocumentMouseMove: function (event) {
    if (this.ifMouseDown) {
      var temp_x = event.clientX - this.x_cord;
      this.el.object3D.rotateY(temp_x / 250);
      this.x_cord = event.clientX;
    }
  },
  OnDocumentTouchStart: function (event) {
    if (event.target.localName === "canvas") {
      const touch = event.touches[0];
      this.x_cord_m = touch.clientX;
      this.y_cord_m = touch.clientY;
      this.ifTouch = true;
    }
  },
  OnDocumentTouchEnd: function (event) {
    if (this.ifTouch && event.target.localName === "canvas") {
      this.ifTouch = false;
    }
  },
  OnDocumentTouchMove: function (event) {
    if (this.ifTouch) {
      const touch = event.touches[0];
      const currentY = this.el.object3D.position.y;
      const temp_x = touch.clientX - this.x_cord_m;
      const temp_y = touch.clientY - this.y_cord_m;
      this.el.object3D.rotateY(temp_x / 200);
      this.el.object3D.position.lerp(
        new THREE.Vector3(
          this.el.object3D.position.x,
          currentY - temp_y / 150 > this.topClamp ||
          currentY - temp_y / 150 < this.bottomClamp
            ? currentY
            : currentY - temp_y / 150,
          this.el.object3D.position.z
        ),
        0.25
      );
      this.y_cord_m = touch.clientY;
      this.x_cord_m = touch.clientX;
    }
  },
});

AFRAME.registerComponent("drag-pan", {
  init: function () {
    this.ifMouseDown = false;
    this.ifTouch = false;
    this.x_cord = 0;
    this.topClamp = 2.05;
    this.bottomClamp = 1.15;
    document.addEventListener(
      "touchstart",
      this.OnDocumentTouchStart.bind(this)
    );
    document.addEventListener("touchend", this.OnDocumentTouchEnd.bind(this));
    document.addEventListener(
      "touchcancel",
      this.OnDocumentTouchEnd.bind(this)
    );
    document.addEventListener("touchmove", this.OnDocumentTouchMove.bind(this));
  },
  OnDocumentTouchStart: function (event) {
    if (event.target.localName === "canvas") {
      const touch = event.touches[0];
      this.y_cord_m = touch.clientY;
      this.ifTouch = true;
    }
  },
  OnDocumentTouchEnd: function (event) {
    if (this.ifTouch && event.target.localName === "canvas") {
      this.ifTouch = false;
    }
  },
  OnDocumentTouchMove: function (event) {
    if (this.ifTouch) {
      const touch = event.touches[0];
      const currentY = this.el.object3D.position.y;
      const temp_y = touch.clientY - this.y_cord_m;
      this.el.object3D.position.lerp(
        new THREE.Vector3(
          this.el.object3D.position.x,
          currentY - temp_y / 150 > this.topClamp ||
          currentY - temp_y / 150 < this.bottomClamp
            ? currentY
            : currentY - temp_y / 150,
          this.el.object3D.position.z
        ),
        0.25
      );
      this.y_cord_m = touch.clientY;

    }
  },
});

AFRAME.registerComponent("camera-movement", {
  schema: {
    x: { type: "number", default: 0 },
    y: { type: "number", default: 2.5 },
    z: { type: "number", default: 2 },
    camInitialized: { type: "boolean", default: false}
  },
  init: function () {
    // console.log(this.data);

  },
  update: function () {
    // console.log('updating ', this.data)
  },
  tick: function () {
    if (this.data.camInitialized) this.el.object3D.position.lerp(new THREE.Vector3(this.data.x, this.data.y, this.data.z), 0.2);
    else this.el.object3D.position.lerp(new THREE.Vector3(0, 25, 20), 0.1);
  }
});
