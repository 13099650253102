import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { getUserAvatarData, postUserAvatarData } from "common/actions/actions";
import { defaultAvatarData } from "utils/default";

export const AuthCtx = createContext({});

const AuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);
  const [hubView, setHubView] = useState(false);
  const [userData, setUserData] = useState({});
  const [userAvatar, setUserAvatar] = useState({});
  const [showLogin, setShowLogin] = useState(false);
  const [newAvatar, setNewAvatar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderColors, setRenderColors] = useState(false);
  const [renderTextures, setRenderTextures] = useState(false);
  const [renderAllMaterials, setRenderAllMaterials] = useState(false);
  const [savedAvatar, setSavedAvatar] = useState({});
  function UserException(message) {
    this.message = message;
    this.name = "UserException";
  }

  function UserNotFound(message) {
    this.message = message;
    this.name = "UserNotFound";
  }

  const updateCanvasHandler = useCallback(
    (avatarUpdate, updateItemID, updateType) => {
      if (updateType === "all") {
        setRenderAllMaterials(true);
      } else if (updateType === "texture") {
        setRenderTextures(true);
      } else if (updateType === "color") {
        setRenderColors(true);
      }
      if (updateType !== "none") setLoading(updateItemID);
      setUserAvatar(avatarUpdate);
    },
    []
  );

  const login = useCallback(
    async (email) => {
      try {
        let getCode;
        let getClient;
        let getHub;
        var dataObject;
        if (email) {
          let avatarData = await getUserAvatarData(email);
          if (avatarData?.data.length === 0)
            throw new UserNotFound("User does not exist");
          setUserAvatar(avatarData.data[0].data);
          setUserData({
            _id: avatarData.data[0]._id,
            userID: avatarData.data[0].userID,
          });
          setSavedAvatar(avatarData.data[0].data);
          setAuthorized(true);
          dataObject = {
            category: "avatar-editor-app",
            action: "logged in",
            label: "email",
            value: email.toString(),
          };
          window.xurealAvatarBridge.appEventAnalytics(dataObject);
        } else {
          const urlSearchParams = new URLSearchParams(window.location.search);
          getCode = urlSearchParams.get("code");
          getClient = urlSearchParams.get("client");
          getHub = urlSearchParams.get("hub");
          window.localStorage.setItem("avatarTenant", getClient);

          if (!getCode) throw new UserException("No User Data");

          if (getClient) {
            console.log("getClient: " + getClient);
          }
          if (getHub) {
            console.log("getClgetHubient: ", getHub);
            setHubView(true);
          }

          let avatarData = await getUserAvatarData(getCode);
          if (avatarData?.data.length === 0)
            throw new UserNotFound("User does not exist");

          setUserAvatar(avatarData.data[0].data);
          setUserData({
            _id: avatarData.data[0]._id,
            userID: avatarData.data[0].userID,
          });
          setSavedAvatar(avatarData.data[0].data);
        }
        setAuthorized(true);
        dataObject = {
          category: "avatar-editor-app",
          action: "logged in",
          label: "code",
          value: getCode,
        };
        window.xurealAvatarBridge.appEventAnalytics(dataObject);
      } catch (e) {
        console.log("thrown error", e);
        if (e.name === "UserException") {
          const urlSearchParams = new URLSearchParams(window.location.search);
          const getHub = urlSearchParams.get("hub");
          if (getHub) setHubView(true);

          setAuthorized(false);
          setShowLogin(true);
        } else if (e.name === "UserNotFound") {
          setNewAvatar(true);
          if (email) {
            setUserData({
              userID: email,
            });
            setUserAvatar(defaultAvatarData);
            setAuthorized(true);
          } else {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const getCode = urlSearchParams.get("code");
            const getClient = urlSearchParams.get("client");
            const getHub = urlSearchParams.get("hub");

            window.localStorage.setItem("avatarTenant", getClient);

            if (!getCode) throw new UserException("No User Data");
            if (getHub) setHubView(true);
            setUserData({
              userID: getCode,
            });
            setUserAvatar(defaultAvatarData);
            setAuthorized(true);
          }
        } else console.error(e);
      }
    },
    [setAuthorized, setUserAvatar, setSavedAvatar, setHubView]
  );

  const postUserAvatar = (avatarData) => {
    const dataURL = document.getElementById("defaultCanvas0").toDataURL();
    const thumbnailURL = document
      .querySelector("a-scene")
      .components.screenshot.getCanvas("perspective")
      .toDataURL();
    return postUserAvatarData(
      userData.userID,
      dataURL,
      thumbnailURL,
      avatarData
    );
  };

  useEffect(() => {
    login();
  }, [login]);

  return (
    <AuthCtx.Provider
      value={{
        authorized,
        login,
        hubView,
        userData,
        userAvatar,
        setUserAvatar: updateCanvasHandler,
        postUserAvatar,
        showLogin,
        newAvatar,
        loading,
        setLoading,
        renderColors,
        setRenderColors,
        renderTextures,
        setRenderTextures,
        renderAllMaterials,
        setRenderAllMaterials,
        savedAvatar,
      }}
    >
      {children}
    </AuthCtx.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthCtx);
};

export default AuthProvider;
