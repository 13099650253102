export const defaultColorPallete = {
  skin: "#ffffff",
  eye: "#ffa047",
  brow: "",
  hair: "#363636",
  shirt: "#3a23e7",
  beard: "",
};

export const defaultCatalog = {
  skin: {
    items: [],
    accessories: {
      freckle: {
        items: [],
      },
      ear: {
        items: [],
      },
      lip: {
        items: [],
      },
      nose: {
        items: [],
      },
      jaw: {
        items: [],
      },
    },
  },
  hair: {
    items: [],
  },
  eye: {
    items: [],
    accessories: {
      brow: {
        items: [],
      },
      glasses: {
        items: [],
      },
    },
  },
  shirt: {
    items: [],
  },
  beard: {
    items: [],
  },
  model: {
    items: [],
  },
};

export const defaultAvatarData = {
  model: {
    id: "03",
    texture_id: "xr-model-3",
    type: "model",
    timestamp: "2021-08-16T18:38:01.000Z",
    value: 5,
    collection: "",
    points: "",
    color_palette_limit: ["no-color"],
    description: "Model for avatar with hair type: bald",
    name: "Avatar Hairtype Bald",
    reference: "",
    morph: "",
    overlay: "",
    primary: 1,
    locked: "",
    active: 1,
  },
  skin: {
    hex: "#ffec64",
    points: [{ shape: "rect", fill: [0, 0, 1030, 1032] }],
    collection: null,
    color_palette_limit: "",
    description: "Avatar Body 1",
    id: "1",
    name: "Body",
    path: null,
    texture: "texture.png",
    texture_id: "xr-skin-1",
    thumbnail: "thumbnail.png",
    timestamp: "2021-08-16T18:38:01.000Z",
    type: "skin",
    value: 5,
  },
  eye: {
    id: "1",
    texture_id: "xr-eye-1",
    type: "eye",
    timestamp: "2021-08-16T18:38:01.000Z",
    value: 5,
    collection: null,
    texture: "texture.png",
    thumbnail: "thumbnail.png",
    path: null,
    points: [
      { shape: "ellipse", fill: [1150, 884, 71] },
      { shape: "ellipse", fill: [1401, 884, 71] },
    ],
    color_palette_limit: "",
    description: "Default eye",
    name: "Eye 1",
    reference: null,
    hex: "#824F25",
  },
  brow: {
    id: "1",
    texture_id: "xr-brow-1",
    type: "brow",
    timestamp: "2021-08-16T18:38:01.000Z",
    value: 5,
    collection: null,
    texture: "texture.png",
    thumbnail: "thumbnail.png",
    path: null,
    points: [{ shape: "rect", fill: [1920, 1800, 500, 400] }],
    color_palette_limit: [],
    description: "Default Eyebrows",
    name: "Eyebrow 1",
    reference: "",
    parent: "eye",
  },
  hair: {
    id: "03",
    texture_id: "xr-hair-3",
    type: "hair",
    timestamp: "2021-08-16T18:38:01.000Z",
    value: 5,
    collection: "",
    points: [ { shape: "rect", fill: [1024, 1028, 2048, 2048] } ],
    color_palette_limit: false,
    description: "Fade style haircut",
    name: "Hair 3 Bald",
    reference: "xr-model-3",
    morph: "",
    overlay: "",
    primary: 1,
    locked: "false",
    active: 1,
    hex: "#D28C52",
  },
  shirt: {
    id: "01",
    texture_id: "xr-shirt-1",
    type: "shirt",
    timestamp: "2021-08-16T18:38:01.000Z",
    value: 0,
    collection: "",
    points: [
      { shape: "rect", fill: [1010, 200, 100, 100] },
      { shape: "rect", fill: [1028, 0, 2048, 650] },
      { shape: "rect", fill: [1600, 512, 500, 520] },
    ],
    color_palette_limit: [],
    description: "This is blank t-shirt",
    name: "Blank Tshirt",
    reference: "",
    morph: "",
    overlay: "",
    primary: 1,
    locked: "false",
    active: 1,
    hex: "#6300FF",
  },
  beard: false,
  logo: false,
  freckle: false,
  glasses: false,
  nose: {
    id: "11",
    texture_id: "xr-nose-1",
    type: "nose",
    timestamp: "2021-08-30T21:28:16.000Z",
    value: null,
    collection: null,
    texture: null,
    thumbnail: "thumbnail.png",
    path: "facial_feature",
    points: null,
    color_palette_limit: false,
    description: "nose",
    name: "Facial Feature: Small Nose",
    reference: "",
    parent: "skin",
  },
  lip: {
    id: "09",
    texture_id: "xr-lip-1",
    type: "lip",
    timestamp: "2021-08-30T21:28:16.000Z",
    value: 0,
    collection: null,
    points: null,
    color_palette_limit: false,
    description: "lip",
    name: "Facial Feature: Medium Lips",
    reference: "",
    morph: '{"name":"Lips_small", "value":1}',
    overlay: null,
    primary: 0,
    locked: null,
    active: 1,
    parent: "skin",
  },
  jaw: {
    id: "14",
    texture_id: "xr-jaw-1",
    type: "jaw",
    timestamp: "2021-08-30T21:28:16.000Z",
    value: null,
    collection: null,
    texture: null,
    thumbnail: "thumbnail.png",
    path: "facial_feature",
    points: null,
    color_palette_limit: false,
    description: "jaw",
    name: "Facial Feature: Default Jaw",
    reference: "",
    parent: "skin",
  },
  ear: {
    id: "4",
    texture_id: "xr-ear-1",
    type: "ear",
    timestamp: "2021-08-30T21:28:16.000Z",
    value: null,
    collection: null,
    texture: null,
    thumbnail: "thumbnail.png",
    path: "facial_feature",
    points: null,
    color_palette_limit: false,
    description: "ears",
    name: "Facial Feature: Default Ears",
    reference: "",
    parent: "skin",
  },
};
