import { useCallback, useEffect, useRef, useState } from "react";
import Sketch from "react-p5";
import { useAuth } from "common/auth";
import { isEmpty } from "utils";
// canvas types: main, sub, color, texture
const TextureCanvas = ({ setCurrentTextureMap }) => {
  const { userAvatar, renderTextures, renderAllMaterials } = useAuth();
  const CONTENT_URL = process.env.REACT_APP_CONTENT_API_URL;
  const firstRender = useRef(true);
  const [p5Container, setP5Container] = useState({});
  const tempFix = useRef(true);
  const loadedImageCache = useRef({});

  const imagePromise = (p5, imageSrc, id) => {
    return new Promise((resolve, reject) => {
      p5.loadImage(
        imageSrc,
        (img) => {
          if (id)
            loadedImageCache.current = {
              ...loadedImageCache.current,
              [id]: img,
            };
          resolve(img);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const mergelayers = useCallback(
    async (p5) => {
      let index = 0;
      const avatarItems = Object.keys(userAvatar).filter(
        (item) => item !== "model"
      );
      const iterationLength = avatarItems.length;

      for (let part of avatarItems) {
        const option = userAvatar[part];
        const applyTextureLoop = (imgData, itemType) => {
          if (itemType === "hair") {
            if (option.texture_id === "xr-hair-3") {
              p5.blendMode(p5.MULTIPLY);
              p5.image(imgData, 0, 0, 2048, 2048);
            } else {
              p5.blendMode(p5.BLEND);
              p5.image(imgData, 0, 0, 2048, 2048);
              p5.blendMode(p5.SOFT_LIGHT);
              p5.image(imgData, 0, 0, 2048, 2048);
            }
          } else if (itemType === "skin") {
            p5.blendMode(p5.MULTIPLY);
            p5.image(imgData, 0, 0, 2048, 2048);
            p5.blendMode(p5.SOFT_LIGHT);
            p5.image(imgData, 0, 0, 2048, 2048);
          } else if (itemType === "freckle") {
            p5.blendMode(p5.BLEND);
            p5.image(imgData, 0, 0, 2048, 2048);
          // } else if (itemType === "overlay") {
          //   p5.blendMode(p5.BLEND);
          //   p5.image(imgData, 0, 0, 2048, 2048);
          } else if (itemType !== "logo") {
            p5.blendMode("multiply");
            p5.image(imgData, 0, 0, 2048, 2048);
          }
        };
        if (
          !option ||
          (option.parent === "skin" && option.type !== "freckle") ||
          option.texture_id === "xr-hair-0"
        ) {
          index++;
        } else {
          p5.blendMode(p5.MULTIPLY);
          if (
            option.texture_id.includes("xr-beard") &&
            loadedImageCache.current.fullBeard
          ) {
            applyTextureLoop(loadedImageCache.current.fullBeard, part);
          } else if (loadedImageCache.current[option.texture_id]) {
            applyTextureLoop(loadedImageCache.current[option.texture_id], part);
          } else {
            const loadedImage = await imagePromise(
              p5,
              `${CONTENT_URL}/${option.parent ? option.parent : option.type}/${
                option.texture_id
              }/texture.png`,
              option.texture_id.includes("xr-beard")
                ? "fullBeard"
                : option.texture_id
            );
            p5.blendMode(
              option.texture_id.includes("xr-beard") ? p5.MULTIPLY : p5.BLEND
            );
            applyTextureLoop(loadedImage, part);
          }
          if (option.overlay && part !== 'shirt') {
            const overlayImage = await imagePromise(
              p5,
              `${CONTENT_URL}/${option.parent ? option.parent : option.type}/${
                option.texture_id
              }/${option.overlay}`
            );
            applyTextureLoop(overlayImage, "overlay");
          }

          index++;
        }
      }
      index++;

      if (index > iterationLength) {
        if (renderAllMaterials || firstRender.current) {
          setCurrentTextureMap(() => p5.canvas.toDataURL("image/png"));
          if (firstRender.current) {
            firstRender.current = false;
          }
        }

        if (renderTextures && !firstRender.current) {
          setCurrentTextureMap(() => p5.canvas.toDataURL("image/png"));
        }
      }
    },
    [
      CONTENT_URL,
      setCurrentTextureMap,
      userAvatar,
      renderAllMaterials,
      renderTextures,
      // setCurrentMaterialMap,
    ]
  );

  const renderToCanvas = useCallback(
    async (p5) => {
      if (
        isEmpty(userAvatar) ||
        (!renderTextures && !renderAllMaterials && !firstRender.current)
      )
        return;
      //-------------------------------
      // This function paints the directions given from the
      // userAvatar to the canvas to create the model texture
      p5.background("black");
      p5.clear();
      p5.noStroke();
      p5.colorMode("hsb");
      p5.scale(0.5);

      if (tempFix.current) {
        p5.blendMode(p5.BLEND);
        p5.fill("black");
        p5.rect(1, 1, 5);
        tempFix.current = false;
      } else {
        p5.blendMode(p5.BLEND);
        p5.fill("red");
        p5.rect(5, 5, 5);
        tempFix.current = true;
      }

      mergelayers(p5);
    },
    [mergelayers, renderAllMaterials, renderTextures, userAvatar]
  );

  // Render textures
  useEffect(() => {
    if (isEmpty(userAvatar) || !renderTextures || firstRender.current) return;
    p5Container.p5.clear();
    p5Container.p5.redraw();
  }, [p5Container.p5, renderTextures, userAvatar]);

  // Render color and texture at same time
  useEffect(() => {
    if (isEmpty(userAvatar) || !renderAllMaterials || firstRender.current)
      return;
    p5Container.p5.clear();
    p5Container.p5.redraw();
  }, [p5Container.p5, renderAllMaterials, userAvatar]);

  // initial render
  useEffect(() => {
    if (firstRender.current) {
      if (!isEmpty(p5Container) && !isEmpty(userAvatar)) {
        p5Container.p5.clear();
        p5Container.p5.redraw();
      }
    }
  }, [p5Container, userAvatar]);

  // useEffect(() => {
  //   if (dispatchTextureCanvas) {
  //     setDispatchTextureCanvas(false)
  //   }
  // }, [p5Container.p5, dispatchTextureCanvas, setDispatchTextureCanvas, setCurrentTextureMap])

  // function that sets up react-p5 component
  // this simply grabs the p5 object and sets it
  // to a state variable
  const preload = (p5) => {
    setP5Container({ p5 });
  };

  // setup function that gets passed into the react-p5 component
  // this creates the canvas, and sets the p5 layer to not loop
  const setup = async (p5, canvasParentRef) => {
    p5.createCanvas(1024, 1024).parent(canvasParentRef);
    p5.clear();
    p5.noLoop();
  };

  // rendering function that gets passed into the react-p5 component
  // this gets called on every update
  const draw = (p5) => {
    renderToCanvas(p5);
  };

  return (
    <div id="avatar-texture-canvas">
      <Sketch preload={preload} setup={setup} draw={draw} />
    </div>
  );
};

export default TextureCanvas;
