//! Meesage system
/***************************************************************
 * Xureal universal message format
 * {"platform":"desktop","data":"hotspot","message":"hotspot-1"}
 *
 */

// the id for the avatar creator application
const appPlatform = "xureal-avatar-app";

// send a message with the data and message as params
export const postCustomMessage = (data, message) => {
  const postData = { platform: appPlatform, data, message };
  console.log("%cAvatar Editor sending custom postMessage: ", "color: orange; font-weight: bold; font-size: 18px;", postData);
  window.parent.postMessage(postData, '*');
};

// send a postmessage with the type of close event as the message
export const postCloseAppMessage = (message) => {
  const postData = { platform: appPlatform, data: "close", message: message || "" };
  console.log("%cAvatar Editor sending close postMessage: ", "color: orange; font-weight: bold; font-size: 18px;", postData);
  window.parent.postMessage(postData, '*');
};
