import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { useAuth } from "common/auth";
import Aframe from "components/Aframe/";
import { AppStyles } from "./App.styles";
import HeroImage from "assets/hero/splash.png";
import MainCanvas, {
  TextureCanvas,
  ColorCanvas,
  SubCanvas,
} from "components/TextureCanvas";
import Modal from "components/ui/Modal";
import { isEmpty } from "utils";
import { defaultCatalog } from "utils/default";
import useWindowSize from "utils/hooks/useWindowSize";
import { getAllCatalogData } from "common/actions/actions";
import LoadingOverlay from "components/ui/Loading";
import { postCloseAppMessage } from "common/messaging";
import Background from "components/Layout/Background";
import Logo from "assets/logos/black-logo.svg";

const LoginPage = lazy(() => import("pages/Login"));
const StudioPage = lazy(() => {
  return Promise.all([
    import("pages/Studio"),
    new Promise((resolve) => setTimeout(resolve, 500)),
  ]).then(([moduleExports]) => moduleExports);
});

function App() {
  const { width, height } = useWindowSize();
  const setModelListener = useRef(false);
  const { authorized, userAvatar, showLogin, newAvatar } = useAuth();

  const [showSkip, setShowSkip] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const [showLoading, setShowLoading] = useState({
    display: true,
  });
  const [showSave, setShowSaveModal] = useState(false);

  const [currentTextureMap, setCurrentTextureMap] = useState("");
  const [currentColorMap, setCurrentColorMap] = useState("");
  const [currentMaterialMap, setCurrentMaterialMap] = useState({
    color: "",
    texture: "",
  });
  const [mainTextureMap, setMainTextureMap] = useState({
    sub: "",
    main: "",
  });

  const [extended, setExtended] = useState(width > height ? 0 : 1);

  const [catalogItems, setCatalogItems] = useState(defaultCatalog);

  const [currentTab, setCurrentTab] = useState("skin");

  const [camInitialized, setCamInitialized] = useState(false);

  useEffect(() => {
    if (newAvatar) setShowLoading({ display: true });
    if (!newAvatar) setShowLoading({ display: true, type: "returning" });
  }, [newAvatar]);

  useEffect(() => {
    return () => {
      if (
        !setModelListener.current &&
        document.getElementById("avatar-model-base")
      ) {
        document
          .getElementById("avatar-model-base")
          .addEventListener("model-loaded", (e) => {
            if (newAvatar) {
              setShowIntro(true);
              setShowLoading({ display: false });
            } else {
              setTimeout(() => {
                setCamInitialized(true);
                setShowLoading({ display: false });
              }, 250);
            }
          });
        setModelListener.current = true;
      }
    };
  });

  useEffect(() => {
    if (!isEmpty(userAvatar) && !newAvatar) {
      setShowIntro(false);
    }
    // eslint-disable-next-line
  }, [userAvatar, newAvatar, showLogin]);

  // Map items to catalog
  useEffect(() => {
    getAllCatalogData()
      .then((res) => {
        res.forEach((category) => {
          const sourceURL = category.config.url;
          const currentCatItem =
            sourceURL.split("/")[sourceURL.split("/").length - 1];
          category.data.forEach((item) => {
            // console.log("item.collection: "+item.collection);
            let localCollection = window.localStorage.getItem("avatarTenant");
            //console.log("localCollection: "+localCollection);
            if (item.collection) {
              if (localCollection === item.collection) {
                console.log("tenant item: " + item.texture_id);
              } else {
                return;
              }
            }

            if (!item.active) return;
            if (item.primary) {
              setCatalogItems((prev) => ({
                ...prev,
                [currentCatItem]: {
                  ...prev[currentCatItem],
                  items: [
                    ...prev[currentCatItem].items,
                    {
                      ...item,
                      color_palette_limit: item.color_palette_limit
                        ? typeof item.color_palette_limit === "string"
                          ? JSON.parse(item.color_palette_limit)
                          : item.color_palette_limit
                        : false,
                    },
                  ],
                },
              }));
            } else {
              setCatalogItems((prev) => {
                return {
                  ...prev,
                  [currentCatItem]: {
                    ...prev[currentCatItem],
                    accessories: {
                      ...prev[currentCatItem].accessories,
                      [item.type]: {
                        items: [
                          ...prev[currentCatItem].accessories[item.type].items,
                          {
                            ...item,
                            parent: currentCatItem,
                            color_palette_limit: item.color_palette_limit
                              ? typeof item.color_palette_limit === "string"
                                ? JSON.parse(item.color_palette_limit)
                                : item.color_palette_limit
                              : false,
                          },
                        ],
                      },
                    },
                  },
                };
              });
            }
          });
        });
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {/* Secondary canvas to save to database at a smaller size */}
      <SubCanvas mainCanvasData={mainTextureMap.main} />
      <ColorCanvas setCurrentColorMap={setCurrentColorMap} />
      <TextureCanvas setCurrentTextureMap={setCurrentTextureMap} />

      {/* Main canvas to display to user */}
      <MainCanvas
        setMainCanvas={setMainTextureMap}
        currentTextureMap={currentTextureMap}
        currentColorMap={currentColorMap}
        currentMaterialMap={currentMaterialMap}
        setCurrentMaterialMap={setCurrentMaterialMap}
        mainTextureMap={mainTextureMap}
        catalogItems={catalogItems}
        setShowLoading={setShowLoading}
        setCamInitialized={setCamInitialized}
        canvasType="main"
      />

      <AppStyles.Overlay className={showIntro && authorized ? "" : "hidden"}>
        <AppStyles.Intro>
          <Background />
          <AppStyles.FloatingLogo src={Logo} />
          <AppStyles.Image
            src={HeroImage}
            isMobile={width < height ? true : false}
          />

          <AppStyles.Title>Create your very own avatar</AppStyles.Title>
          <AppStyles.Text>
            Your avatar will be used throughout the entire Metaverse experience.
          </AppStyles.Text>

          {/* <AppStyles.InvisButton>Skip for Now</AppStyles.InvisButton> */}
          {width >= height && (
            <>
              <AppStyles.Button
                style={{
                  position: "absolute",
                  bottom: "5%",
                  backgroundColor: "#2f50ff",
                  marginTop: "20px",
                }}
                onClick={() => {
                  setTimeout(() => setCamInitialized(true), 100);
                  setShowIntro(false);
                }}
              >
                Create my avatar
              </AppStyles.Button>
              {/* <AppStyles.SkipButton
                style={{ position: "absolute", bottom: "5%", right: "32px" }}
                onClick={() => setShowSkip(true)}
              >
                Skip for Now
              </AppStyles.SkipButton> */}
            </>
          )}
          {width < height && (
            <AppStyles.ButtonBlock>
              <AppStyles.Button
                onClick={() => {
                  setTimeout(() => setCamInitialized(true), 100);
                  setShowIntro(false);
                }}
                style={{ backgroundColor: "#2f50ff" }}
              >
                Create my avatar
              </AppStyles.Button>
              {/* <AppStyles.SkipButton onClick={() => setShowSkip(true)}>
                Skip for Now
              </AppStyles.SkipButton> */}
            </AppStyles.ButtonBlock>
          )}
        </AppStyles.Intro>
      </AppStyles.Overlay>

      {showLoading?.display && <LoadingOverlay type={showLoading?.type} />}

      {!authorized && showLogin && (
        <AppStyles.Fadein>
          <LoginPage setShowLoading={setShowLoading} />
        </AppStyles.Fadein>
      )}

      {authorized && (
        <StudioPage
          extended={extended}
          setExtended={setExtended}
          setShowSkip={setShowSkip}
          showSave={showSave}
          setShowSaveModal={setShowSaveModal}
          catalogItems={catalogItems}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setShowLoading={setShowLoading}
        />
      )}
      {userAvatar.model && (
        <Aframe
          currentTextureMap={mainTextureMap.main}
          extendedDrawer={extended}
          catalogItems={catalogItems}
          currentTab={currentTab}
          camInitialized={camInitialized}
        />
      )}

      {/* Need to map these states based on the usertype */}

      <Modal
        showModal={showSkip}
        handleClose={() => setShowSkip(false)}
        title={
          newAvatar
            ? "Are you sure you want to skip building your avatar?"
            : "Are you sure you want to leave the avatar builder?"
        }
        body={
          newAvatar
            ? "If so, you can create your avatar later by visiting your profile page."
            : "Any changes you made will not be saved. Do you want to leave anyway?"
        }
        cancelText={newAvatar ? "No, go back" : "No, stay"}
        cancelCallback={() => setShowSkip(false)}
        confirmText={newAvatar ? "Yes, skip for now" : "Yes, leave"}
        confirmCallback={() => {
          var dataObject = {
            category: "avatar-editor-app",
            action: newAvatar ? "skipped" : "closed",
            label: "event closed",
            value: "1",
          };
          window.xurealAvatarBridge.appEventAnalytics(dataObject);
          window.xurealAvatarBridge.appEventClosed(
            newAvatar ? "skipped" : "closed"
          );
          postCloseAppMessage(newAvatar ? "skipped" : "closed");
        }}
      />
    </Suspense>
  );
}

export default App;
