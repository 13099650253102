import React from 'react';
import PinkGradient from 'assets/ui/color-gradient-pink.png';
import BlueGradient from 'assets/ui/color-gradient-blue.png';
import styled from 'styled-components';

const BackgroundStyles = {
Background: styled.div`
z-index: -100;
position: absolute;
top: 0;
left: 0;
height: 100%;
width: 100%;
background-color: #ffffff;
`,

PinkGradient: styled.img`
opacity: .4;
max-width: none;
filter: blur(40px);
position: fixed;
right: 0;
left: auto;
transform: translate(60%, 10%);
`,

BlueGradient: styled.img`
opacity: .4;
max-width: none;
filter: blur(40px);
position: fixed;
right: auto;
left: 0;
transform: translate(-55%, 10%);
`,
}

const Background = () => {
    return(
        <BackgroundStyles.Background>
            <div style={{position: 'relative'}}>
            <BackgroundStyles.BlueGradient src={BlueGradient} />
            <BackgroundStyles.PinkGradient src={PinkGradient} />
            </div>
        </BackgroundStyles.Background>
    )
}

export default Background;