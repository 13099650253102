import { useCallback, useEffect, useRef, useState } from "react";
import Sketch from "react-p5";
import { defaultColorPallete } from "utils/default";
import { useAuth } from "common/auth";
import { isEmpty, compareObjects } from "utils";

// canvas types: main, sub, color, texture

const ColorCanvas = ({ setCurrentColorMap }) => {
  const { userAvatar, renderColors, renderAllMaterials } = useAuth();
  const firstRender = useRef(true);
  const prevUserData = useRef({});
  const [p5Container, setP5Container] = useState({});

  const configurePoints = (points, p5) => {
    // This function translates the data stored in the points
    // column for each texture into a function to paint to the canvas
    // it splits an array in to individual arguments
    //   if the last argument is missing, it uses the second to last arg
    if (typeof points === "string") points = JSON.parse(points);
    for (const pointSystem of points) {
      if (pointSystem.blend) p5.blendMode(pointSystem.blend);
      else p5.blendMode(p5.BLEND);
      let fillShapeFunction = (a, b, c, d) => p5[pointSystem.shape](a, b, c, d);
      if (pointSystem.color) {
        const color =
          pointSystem.color.length > 1
            ? p5.color(
                pointSystem.color[0],
                pointSystem.color[1],
                pointSystem.color[2]
              )
            : p5.color(pointSystem.color[0]);
        if (pointSystem.alpha) color.setAlpha(pointSystem.alpha);

        p5.fill(color);
        fillShapeFunction(
          pointSystem.fill[0],
          pointSystem.fill[1],
          pointSystem.fill[2],
          pointSystem.fill[3] ? pointSystem.fill[3] : pointSystem.fill[2]
        );
      }
      if (!pointSystem.color) {
        fillShapeFunction(
          pointSystem.fill[0],
          pointSystem.fill[1],
          pointSystem.fill[2],
          pointSystem.fill[3] ? pointSystem.fill[3] : pointSystem.fill[2]
        );
      }
    }
  };

  const colorLayer = useCallback(
    (p5) => {
      for (const textureItem in userAvatar) {
        if (
          userAvatar?.[textureItem]?.points &&
          userAvatar?.[textureItem]?.points?.length > 0
        ) {
          p5.blendMode(p5.BLEND);
          if (textureItem === "beard") {
            p5.fill(
              userAvatar?.[textureItem]?.hex
                ? userAvatar?.[textureItem]?.hex
                : userAvatar?.hair?.hex || defaultColorPallete.hair
            );
            configurePoints(userAvatar?.[textureItem]?.points, p5);
          } else if (textureItem === "hair") {
            p5.blendMode(p5.BLEND);
            p5.fill(userAvatar?.hair?.hex || defaultColorPallete.hair);
            configurePoints(userAvatar?.hair?.points, p5);
            p5.blendMode(p5.MULTIPLY);
            p5.fill(userAvatar?.hair?.hex || defaultColorPallete.hair);
            configurePoints(userAvatar?.hair?.points, p5);
            if (!userAvatar?.beard) {
              p5.fill(userAvatar?.hair?.hex || defaultColorPallete.hair);
              configurePoints(
                [
                  { shape: "rect", fill: [0, 1016, 1024, 360] },
                  { shape: "rect", fill: [0, 1300, 580, 235] },
                  { shape: "rect", fill: [830, 1300, 200, 256] },
                ],
                p5
              );
            }
          } else if (textureItem !== "brow") {
            p5.fill(userAvatar?.[textureItem]?.hex || defaultColorPallete.skin);
            configurePoints(userAvatar?.[textureItem]?.points, p5);
          }
        }
      }
      if (userAvatar.brow) {
        p5.blendMode(p5.BLEND);
        p5.fill(
          userAvatar?.brow?.hex
            ? userAvatar?.brow?.hex
            : userAvatar?.hair?.hex || defaultColorPallete.hair
        );
        configurePoints(userAvatar?.brow?.points, p5);
      }
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    },
    [userAvatar]
  );

  const renderToCanvas = useCallback(
    async (p5) => {
      // if (isEmpty(userAvatar)) return;

      //-------------------------------
      // This function paints the directions given from the
      // userAvatar to the canvas to create the model texture
      p5.background("black");
      p5.clear();
      p5.noStroke();
      p5.colorMode("hsb");
      p5.scale(0.5);
      await colorLayer(p5);

      if (renderColors || renderAllMaterials || firstRender.current) {
        setCurrentColorMap(p5.canvas.toDataURL("image/png"));
        if (firstRender.current) firstRender.current = false;
      } else {
        setCurrentColorMap(p5.canvas.toDataURL("image/png"));
      }
    },
    [
      setCurrentColorMap,
      colorLayer,
      // userAvatar,
      renderColors,
      renderAllMaterials,
      // setCurrentMaterialMap,
    ]
  );

  // // Updating just Color
  // useEffect(() => {
  //   if (isEmpty(userAvatar) || !renderColors || firstRender.current) return;
  //   p5Container.p5.clear();
  //   p5Container.p5.redraw();
  // }, [renderColors, userAvatar, p5Container.p5]);

  // // Updating both Texture and Color at same time
  // useEffect(() => {

  //   if (isEmpty(userAvatar) || !renderAllMaterials || firstRender.current)
  //     return;
  //   p5Container.p5.clear();
  //   p5Container.p5.redraw();
  // }, [p5Container.p5, renderAllMaterials, userAvatar]);

  // Initial render of Canvases
  useEffect(() => {
    // if (firstRender.current) {
    if (
      !isEmpty(p5Container) &&
      !isEmpty(userAvatar) &&
      !compareObjects(prevUserData.current, userAvatar)
    ) {
      p5Container.p5.clear();
      p5Container.p5.redraw();
      prevUserData.current = userAvatar
    }
    // }
  }, [p5Container, userAvatar, renderColors, renderAllMaterials]);

  // function that sets up react-p5 component
  // this simply grabs the p5 object and sets it
  // to a state variable
  const preload = (p5) => {
    setP5Container({ p5 });
  };

  // setup function that gets passed into the react-p5 component
  // this creates the canvas, and sets the p5 layer to not loop
  const setup = async (p5, canvasParentRef) => {
    p5.createCanvas(1024, 1024).parent(canvasParentRef);
    p5.clear();
    p5.noLoop();
  };

  // rendering function that gets passed into the react-p5 component
  // this gets called on every update
  const draw = (p5) => {
    renderToCanvas(p5);
  };

  return (
    <div id="avatar-color-canvas">
      <Sketch preload={preload} setup={setup} draw={draw} />
    </div>
  );
};

export default ColorCanvas;
