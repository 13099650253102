import styled from "styled-components";

export const LoadingStyles = {};

LoadingStyles.Overlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  z-index: 95;
  background-color: white;
`;

LoadingStyles.Suspense = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  flex: 1;
`;

LoadingStyles.SuspenseImg = styled.img`
  margin-top: 10vh;
  width: 100%;
  max-width: 666px;
  @media (max-width: 600px) {
    max-width: 236px;
  }
`;

LoadingStyles.SuspenseTitle = styled.div`
  color: #3e4459;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  margin-top: 20px;
  @media (max-width: 600px) {
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
    text-align: center;
  }
`;

LoadingStyles.SuspenseText = styled.div`
  margin-top: 24px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0.44px;
  line-height: 21px;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 12px;
    letter-spacing: 0.35px;
    line-height: 21px;
    text-align: center;
  }
`;

LoadingStyles.TransparentOverlay = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 95;
  background: #ffffff;
`;

LoadingStyles.Box = styled.div`
  padding: 5% 5%;
  height: 60%;
  max-height: 468px;
  width: 50%;
  max-width: 536px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    height: 45%;
    max-height: 360px;
    width: 90%;
    max-width: 360px;
  }
`;

LoadingStyles.LoadingImage = styled.img`
  width: 50%;
  max-width: 120px;
  width: 100%;
  @media (max-width: 600px) {
    max-width: 64px;
    height: auto;
  }
`;

LoadingStyles.LoadingTitle = styled.div`
  margin: 20px auto 0;
  color: #000000;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.88px;
  line-height: 37px;
  text-align: center;
  width: 350px;
  @media (max-width: 600px) {
    margin: 24px auto 0;
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
  }
  @media (max-width: 350px) {
    width: auto;
  }
`;
LoadingStyles.LoadingSubtitle = styled.div`
  margin: 12px auto 0;
  color: #8b8b97;
  font-size: 15px;
  letter-spacing: 0.44px;
  line-height: 21px;
  text-align: center;
  @media (max-width: 350px) {
    display: none;
  }
`;
